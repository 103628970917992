<template>
  <div>
    <!-- 大屏首页 -->
    <section class="section p0" style="padding-bottom: 0">
      <div class="col-lg-12 p0 container_max">
        <img
          src="@/assets/images/hire/hire_bj.png"
          style="width: 100%; height: 100%; object-fit: fill"
          alt=""
        />
        <div class="center_box flex-cc">
          <h1 class="T64px_Bold colorfff">出租房产</h1>
          <span class="T20px_Regular colorfff text_personal"
            >Personal Real Estate Corporation</span
          >
          <span class="T20px_Regular colorfff zh_text_personal"
            >傅聪个人房地产公司</span
          >
        </div>
      </div>
    </section>
    <!-- 全部房子 -->
    <section class="section all_house">
      <div class="container flex-w">
        <div class="screen_type_box">
          <span class="T12px_Regular color828282">房产类型</span>
          <div class="screen_type_list flex-w">
            <div
              v-for="(item, index) in screenList"
              class="flex-cc screen_type_item"
              :class="index + 1 == screenIndex ? 'act' : ''"
              :key="index"
              @click="changeScree(index + 1)"
            >
              <img
                :src="item.icon"
                :style="`width:${item.w};height:${item.h};`"
                alt=""
              />
              <span class="screen_type_name T14px_Regular">
                {{ item.title }}
              </span>
              <img
                src="@/assets/images/hire/noact.png"
                v-if="index + 1 != screenIndex"
                class="noact_icon"
                alt=""
              />
              <img
                src="@/assets/images/hire/xzact.png"
                v-else
                class="noact_icon"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="house_info_list col-lg-12 col-12">
          <header class="color828282 flex-c T12px_Regular">
            当前搜索{{ total }}个结果
          </header>

          <div class="house_list flex-w col-12">
            <div
              class="house_list_item flex-w"
              v-for="item in list"
              :key="item.id"
            >
              <div class="img_box">
                <img class="house_logo" :src="item.photo[0]" alt="" />
              </div>
              <div class="flex-c info_box">
                <span class="T12px_Regular colorA6B565">优质房源</span>
                <span class="T24px_Regular color333 name">{{ item.name }}</span>
                <span class="T16px_Regular color333 area"
                  >{{ item.size }} sqft/总面积</span
                >

                <div class="label_box colorA6B565 flex-w">
                  <span class="T24px_Regular"
                    >${{ numberFormat(item.price) }}</span
                  >
                  <span class="xian"></span>
                  <span class="T24px_Regular">月</span>
                </div>
              </div>
              <div class="flex-c footer_box">
                <div class="col-lg-12 footer_info_box flex-w">
                  <img
                    class="info_icon"
                    src="@/assets/images/home/brd.png"
                    alt=""
                  />
                  <span class="T16px_Regular color333">
                    {{ item.parking }} Bed
                  </span>
                </div>
                <div class="col-lg-12 footer_info_box flex-w">
                  <img
                    class="info_icon"
                    src="@/assets/images/home/bathroom.png"
                    alt=""
                  />
                  <span class="T16px_Regular color333">
                    {{ item.toilet }} Bathroom
                  </span>
                </div>
                <div
                  @click="infoRouter(item.id)"
                  class="col-lg-12 footer_info_box flex-w"
                >
                  <button
                    class="T14px_Regular w170px_btn_h color333 animation_right"
                  >
                    查看详情
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pagination_box flex-wc" v-show="total">
          <button
            class="previous_btn T18px_Regular"
            @click="pagingHardcover(goodParam.page - 1)"
          >
            上一页
          </button>
          <button
            class="number_btn T18px_Regular"
            @click="pagingHardcover(item)"
            :class="item == goodParam.page ? 'act' : ''"
            v-for="item in goodParam.maxPage"
            :key="item"
          >
            {{ item }}
          </button>
          <button
            class="net_btn T18px_Regular"
            @click="pagingHardcover(goodParam.page + 1)"
          >
            下一页
          </button>
        </div>
      </div>
    </section>

    <section class="section card_box">
      <div class="container flex-wc">
        <img
          class="footer_img_logo"
          src="@/assets/images/home/footer_img.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
import { houseList, dictInfo } from "@/api/api.js";
export default {
  data() {
    return {
      screenList: [
        {
          icon: require("../../assets/images/hire/s1.png"),
          title: "公寓",
          w: ".8125rem",
          h: ".9063rem",
        },
        {
          icon: require("../../assets/images/hire/s2.png"),
          title: "联排别墅",
          w: "1.375rem",
          h: ".9063rem",
        },
        {
          icon: require("../../assets/images/hire/s3.png"),
          title: "独栋别墅",
          w: "1.0313rem",
          h: "1.0313rem",
        },
      ],
      screenIndex: 1,
      pages: 100,
      currentPage: 5,

      list: [],
      total: 0,
      goodTotal: 0,
      goodParam: {
        pageSize: 4,
        page: 1,
        maxPage: 1,
        house_type: 1,
        type: 1,
      },
      //地点
      locationList: [],
      //地产类型
      realEstateTypeList: [],
      //交易类型
      payTypeList: [],
      //房产尺寸
      propertySizeList: [],
      //地产年限
      realEstateTenureList: [],
      //预算
      budgetList: [],
    };
  },
  created() {
    this.getHouseList();

    this.getDictList("locationNmae", "locationList");
    this.getDictList("real_estate_type", "realEstateTypeList");
    this.getDictList("pay_type", "payTypeList");
    this.getDictList("property_size", "propertySizeList");
    this.getDictList("real_estate_tenure", "realEstateTenureList");
    this.getDictList("budget", "budgetList");
  },
  methods: {
    /**
     * 房子类型赛选
     * @param {*} index 当前选中的房子类型数值
     */
    changeScree(index) {
      this.screenIndex = index;
      this.goodParam.house_type = index;
      this.getHouseList();
    },
    linkGen(pageNum) {
      this.currentPage = pageNum;
    },
    infoRouter(id) {
      this.$router.push({
        path: "houseInfo",
        query: { id: id },
      });
    },
    /**
     * 获取字典接口
     * @param {String} name 字典名称标题
     * @param {String} pramsName 赋值名称
     */
    getDictList(name, pramsName) {
      dictInfo({ name: name }).then((res) => {
        this[pramsName] = res.data;
      });
    },
    /**
     *精品房源分页
     @param {Nuber} page 分页
     */
    pagingHardcover(page) {
      if (
        page &&
        this.goodParam.page != page &&
        this.goodParam.maxPage >= page
      ) {
        this.goodParam.page = page;
        this.getHouseList();
      }
    },
    /**
     * 获取房源列表
     */
    getHouseList() {
      houseList(this.goodParam).then((res) => {
        this.list = res.list;
        this.total = res.total;
        this.goodParam.maxPage = res.maxPage;
      });
    },
    /**
     *千元转换符
     */
    numberFormat(number) {
      let formatter = new Intl.NumberFormat("en-US"); // 使用德国的环境来格式化数字
      let formattedNumberDE = formatter.format(number);

      return formattedNumberDE;
    },
  },
};
</script>

<style lang="less" scoped>
.container_max {
  position: relative;
  height: 17.4688rem;

  .center_box {
    position: absolute;
    height: 10.5625rem;
    background-image: url("../../assets/images/home/home_bj.png");
    background-size: 10.625rem 10.5625rem;
    background-repeat: no-repeat;
    background-position: center;
    bottom: 1.4063rem;
    left: 0;
    right: 0;

    .text_personal,
    .zh_text_personal {
      letter-spacing: 0.1563rem;
      height: 0.75rem;
    }

    .zh_text_personal {
      margin-top: 0.5rem;
    }
  }
}

.all_house {
  padding-top: 1.7188rem;
  padding-bottom: 4.6875rem;
  background: #fff;
  position: relative;

  .screen_type_box {
    .screen_type_list {
      margin-top: 0.625rem;
    }

    .screen_type_item {
      width: 3.75rem;
      height: 2.8438rem;
      background: #e0e0e0;
      border-radius: 0.2188rem;
      border: 0.0313rem solid #e0e0e0;
      margin-right: 1.1875rem;
      transition: all 0.5s;
      position: relative;

      .noact_icon {
        position: absolute;
        width: 0.5625rem;
        height: 0.5625rem;
        top: 0.3125rem;
        right: 0.3125rem;
      }

      .screen_type_name {
        height: 1.125rem;
        line-height: 1.4063rem;
      }
      &:hover {
        background: #ffffff;
        border: 0.0313rem solid #ffffff;
        transition: all 0.5s;
        box-shadow: 0px 0.1563rem 0.75rem 0.25rem rgba(195, 195, 195, 0.25);
      }
      &.act {
        background: #ffffff;
        border: 0.0313rem solid #ffffff;
        transition: all 0.5s;
        box-shadow: 0px 0.1563rem 0.75rem 0.25rem rgba(195, 195, 195, 0.25);
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 1.0313rem;
          height: 0.0938rem;
          background: #a8b565;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }

  .house_info_list {
    margin-top: 1.4375rem;

    .house_list {
      margin-top: 0.3125rem;

      .house_list_item {
        width: 100%;
        overflow: hidden;
        border-right: 0.0313rem solid #d9d9d9;
        border-bottom: 0.0313rem solid #d9d9d9;
        margin-bottom: 1.0938rem;

        .img_box {
          position: relative;
          width: 7.0938rem;
          height: 4.0625rem;
          overflow: hidden;

          .house_logo {
            width: 7.0938rem;
            height: 4.0625rem;
            transition: all 0.5s;
          }
        }

        .info_box {
          // padding: 0.8125rem 1.2188rem 1.125rem 1.2188rem;
          // height: 3rem;
          margin-left: 1.3438rem;
          width: 6.0875rem;
          border-right: 0.0313rem solid #e0e0e0;
          .name {
            width: 100%;
            height: 1.1875rem;
            line-height: 1.1875rem;
            // margin: 0.25rem 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .label_box {
            margin-top: 0.5313rem;
            .xian {
              display: inline-block;
              height: 0.7188rem;
              width: 0.0625rem;
              margin: 0 0.5313rem;
              background: #a8b565;
            }
          }
        }

        .footer_box {
          width: 7.2563rem;

          border-top: none;

          .footer_info_box {
            padding-left: 1.075rem;
            position: relative;
            margin-bottom: 0.325rem;
            .info_icon {
              width: 0.6875rem;
              height: 0.5rem;
              margin-right: 0.375rem;
            }
            .w170px_btn_h {
              margin-top: 0.325rem;
            }
          }
        }

        &:hover {
          .house_logo {
            transform: scale(1.2);
            transition: all 0.5s;
          }
        }
      }
    }
  }

  .pagination_box {
    margin-top: 1.875rem;
    width: 100%;
    .previous_btn,
    .net_btn {
      width: 2.4063rem;
      height: 0.9375rem;
      line-height: 0.9375rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: none;
      color: #ffffff;
    }
    .number_btn {
      width: 0.9688rem;
      height: 0.9375rem;
      background: #232020;
      border-radius: 0.0625rem;
      border: none;
      color: #ffffff;
      margin-right: 0.4063rem;
      &:nth-child(2) {
        margin-left: 0.4063rem;
      }
      &.act {
        background: #fff;
        color: #000000;
      }
    }
  }
}

.card_box {
  background: #a6b565;
  padding: 0.875rem 0;
  border-radius: 0;
  .footer_img_logo {
    width: 20.75rem;
  }
}
</style>
